<template>
    <div class="praise">
        <div class="contents text-box type1">
            <ul class="text-box__list">
                <li class="text-box__item">의료진, 간호사, 병동 직원 분들에게 따뜻한 말한마디 전해주세요.</li>
                <li class="text-box__item">근거없는 비방 및 욕설 광고성 글은 관리자의 판단에 따라 무통보 삭제될 수 있습니다.</li>
                <li class="text-box__item">등록된 글은 마이페이지 > 나의 작성 글 > 칭찬합니다에서 확인이 가능하며 승인된 글은 수정이 불가합니다.</li>
                <li class="text-box__item">승인된 글은 타인이 열람 가능하므로 작성 시 개인정보는 노출되지 않게 유의해주시기 바랍니다. </li>
                <li class="text-box__item">개인정보 수정을 원할 경우 마이페이지 > 회원정보 > 회원정보 수정 메뉴를 이용해 주시기 바랍니다.</li>
            </ul>
        </div>
        <div class="contents text-box type2">
            <h3 class="text-box__tit">개인정보 수집 및 이용목적에 대한 동의 <span class="c-red">(필수)</span></h3>
            <div class="text-box__wrap">
                <p>
                    1. 개인정보 등의 수집 · 이용목적 : 창원파티마병원은 ‘칭찬합니다’ 서비스 이용 시 기초 자료 수집을 위하여 다음과 같이 귀하의 개인정보를 수집 이용합니다. 
                </p>
                <p>
                    2. 수집하려는 개인정보 항목 : (필수) 이름, 휴대전화번호, 이메일, 환자와의 관계 / (선택) 칭찬 직원(의료진), 부서/병동
                </p>
                <p>
                    3. 개인정보 등의 보유 및 이용기간
                </p>
                <p class="emphasize_txt blue__title">
                    1. 홈페이지 회원 탈퇴시 파기
                </p>
                <p class="emphasize_txt blue__title">
                    2. 답변일로부터 2년 
                </p>
                <p>
                    4. 동의를 거부할 권리 / 동의거부에 따른 안내 <b></b>
                    개인정보 수집 및 이용에 대해 거부할 권리가 있으며 다만, 개인정보 수집동의 거부 시에는 ‘칭찬합니다’ 서비스 이용이 제한됩니다. 
                </p>

            </div>
        </div>
        <div class="agree-input">
            <label for="agree-y" class="agree-input__item">
                <input type="radio" id="agree-y" class="agree-input__radio" name="agree" value="Y" v-model="params.agree">
                <span class="icon"></span>
                동의합니다
            </label>
            <label for="agree-n" class="agree-input__item">
                <input type="radio" id="agree-n" class="agree-input__radio" name="agree" value="N" v-model="params.agree">
                <span class="icon"></span>
                동의하지 않습니다
            </label>
        </div>

        <div class="contents input-form type1">
            <p class="input-form__top-text"><span class="c-red">* </span> 필수 입력항목</p>
            <ul class="input-form__list">
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit">이름</div>
                        <div class="cont">{{$store.state.myInfoStore.memName}}</div>
                    </div>
                </li>
                <li class="input-form__item item2">
                    <div class="input-form__box">
                        <div class="tit">휴대전화</div>
                        <div class="cont">{{$store.state.myInfoStore.memHpNo}}</div>
                    </div>
                    <div class="input-form__box">
                        <div class="tit">이메일</div>
                        <div class="cont">{{$store.state.myInfoStore.memEmail}}</div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>환자와의 관계</div>
                        <div class="cont radio">
                            <div class="input-form__radio">
                                <label class="input-item">
                                    <input type="radio" class="radio" name="radio1" @change="parentInit" v-model="params.item1" value="본인">
                                    <span class="icon"></span>
                                    본인
                                </label>
                                <label class="input-item">
                                    <input type="radio" class="radio" name="radio1" v-model="params.item1" value="보호자">
                                    <span class="icon"></span>
                                    보호자
                                </label>
                            </div>
                            <label class="cont__text">
                                <input type="text" class="cont__text-input" :disabled="params.item1 !== '보호자'" v-model="params.item2" ref="item2">
                                <p class="present">보호자 선택 시 환자명을 입력해 주세요.</p>
                            </label>
                        </div>
                    </div>
                </li>
                <li class="input-form__item item2">
                    <div class="input-form__box">
                        <div class="tit">칭찬 직원(의료진)</div>
                        <div class="cont"><input type="text" class="cont__text-input" v-model="params.item3"></div>
                    </div>
                    <div class="input-form__box">
                        <div class="tit">부서/병동</div>
                        <div class="cont"><input type="text" class="cont__text-input" v-model="params.item4"></div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>제목</div>
                        <div class="cont"><input type="text" class="cont__text-input" v-model="params.title" ref="title"></div>
                    </div>
                </li>
                <li class="input-form__item desc">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>내용</div>
                        <div class="cont">
                            <textarea name="" id="" v-model="params.content" ref="content" @input="checkByteLength"></textarea>
                             <p class="text_byte"><span class="red__title">*</span> {{ byteCount }} / 1000 bytes</p>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="contents btn-wrap center">
                <a href="javascript:;" class="btn" @click="cancelArticle">취소</a>
                <a href="javascript:;" class="btn blue" @click="insertArticle">등록</a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
        props: {
            boardInfo: ''
        },
        data() {
            return {
                params: { content: '', },
                byteCount: 0 // 현재 바이트 수
            }
        },
        watch: {
            'params.content'(newVal) {
                this.checkByteLength(); // 바이트 수 업데이트
            }
        },
        methods: {
            cancelArticle() {
                if (confirm('글 작성을 취소하시겠습니까?')) {
                    this.$router.push({ path: `${this.$route.path.replace('/register.do', '/list.do')}` })
                }
            },
            insertArticle() {
                if (this.params.agree != "Y") {
                    alert("개인정보 수집 및 이용목적에 대한 동의가 필요합니다.");
                    return
                }
                if (!this.params.item1) {
                    alert('환자와의 관계를 체크해 주세요.');
                    return;
                }
                if (this.params.item1 == '보호자' && (!this.params.item2 || !this.params.item2.trim())) {
                    alert('보호자를 입력해주세요.');
                    this.$refs.item2.focus();
                    return;
                }
                if (!this.params.title) {
                    alert('제목을 입력해 주세요.');
                    this.$refs.title.focus()
                    return;
                }
                if (!this.params.content) {
                    alert('내용을 입력해 주세요.');
                    this.$refs.content.focus()
                    return;
                }

                if (confirm('등록하시겠습니까?')) {
                    var bid = 5

                    this.params.item18 = 'R'

                    this.axios({
                        method: "post",
                        url: this.api.article.insert + bid,
                        headers: { 'Content-type': 'multipart/form-data' },
                        data: this.params
                    }).then(response => {
                        alert("관리자 승인 후 노출됩니다.");
                        this.$router.push({ path: `${this.$route.path.replace('/register.do', '/list.do')}` })
                    }).catch((err) => {
                        alert('오류가 발생했습니다. 다시 시도해주시길 바랍니다.')
                        location.reload(true);
                    });
                }
            },
            checkByteLength() {
                const byteLength = new Blob([this.params.content]).size; // 바이트 수 계산
                this.byteCount = byteLength;

                if (byteLength > 1000) {
                    alert('입력 횟수가 초과되었습니다.');
                    this.trimContentToLimit(); // 1000 바이트를 넘으면 내용 잘라냄
                }
            },
            trimContentToLimit() {
                let content = this.params.content;
                let byteLength = 0;
                let trimmedContent = '';

                // 바이트 수가 1000을 넘지 않도록 글자를 하나씩 확인하며 잘라냄
                for (let i = 0; i < content.length; i++) {
                    let char = content[i];
                    byteLength += new Blob([char]).size;
                    if (byteLength > 1000) break;
                    trimmedContent += char;
                }

                this.params.content = trimmedContent; // 잘린 내용을 다시 적용
                this.byteCount = new Blob([this.params.content]).size; // 바이트 수 갱신
            }
        },
        mounted() {
            this.checkByteLength(); // 처음 로드될 때 바이트 수 체크
        },
    }
</script>
