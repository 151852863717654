<template>
    <NameCertifyPopUp ref="NameCertifyPopUp" :popupVisible="popupVisible" @hide-popup="hidePopup"></NameCertifyPopUp>

    <div class="tab-menu__wrap">
        <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <li class="tab-menu__item on"><button type="button" class="tab-menu__btn" disabled>진료과 예약</button></li>
                <li class="tab-menu__item"><button type="button" class="tab-menu__btn" @click="$commonUtils.goMenu('/reserve/reservationDoctor.do')">의료진 예약</button></li>
            </ul>
        </div>
    </div>
    <div class="contents">
        <div class="reservation input-form type1">
            <ul class="reservation__left">
                <li class="reservation__step" :class="step == 1 ? 'active' : ''" >
                    <a href="javascript:;" class="reservation__header" @click="selectStep(1)" ref="rHeader">
                        <h3 class="reservation__header--title">STEP01</h3>
                        <span class="reservation__header--arrow"></span>
                        <h3 class="reservation__header--title">{{ !selectedDept.deptNm ? '진료과를 선택해주세요' : selectedDept.deptNm }}</h3>
                    </a>

                    <div class="reservation__con" :class="step == 1 ? 'active' : ''">
                        <div class="reservation__consonant">
                            <consonant @consonant="searchConsonant" :list="consonantList"></consonant>
                        </div>
                        <div class="reservation__department-list">
                            <ul>
                                <template v-for="(department, idx) in departmentList" :key="idx">
                                    <li v-if="department.rsvOpenYn !== 'N'">
                                        <a href="#" @click="selectDept(department)">{{ department.deptNm }}</a>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </li>
                <li class="reservation__step step02" :class="step == 2 ? 'active' : ''">
                    <a href="javascript:;" class="reservation__header" @click="selectStep(2)">
                        <h3 class="reservation__header--title">STEP02</h3>
                        <span class="reservation__header--arrow"></span>
                        <h3 class="reservation__header--title">{{ !selectedDoctor.drName ? '의료진을 선택해주세요' : selectedDoctor.hptlJobTitle ? selectedDoctor.hptlJobTitle : selectedDoctor.drName }}</h3>
                    </a>
                    <div class="reservation__con" :class="step == 2 ? 'active' : ''" >
                        <ul class="reservation__input">
                            <li class="input-form__item">
                                <div class="input-form__box">
                                    <div class="cont radio">
                                        <div class="input-form__radio">
                                            <label class="input-item">
                                                <input type="radio" class="radio" name="radio1" checked @click="$commonUtils.objArrSort(doctorList, 'drName', 'ASC')">
                                                <span class="icon"></span>
                                                기본순
                                            </label>
                                            <label class="input-item">
                                                <input type="radio" class="radio" name="radio1" @click="$commonUtils.objArrSort(doctorList, 'fastDt', 'ASC')">
                                                <span class="icon"></span>
                                                예약빠른순
                                            </label>
                                        </div>

                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="reservation__doctor-list">
                            <ul>
                                <template v-if="doctorList.length > 0">
                                    <template v-for="(doctor, idx) in doctorList" :key="idx">
                                        <li class="reservation__doctor" v-if="doctor.doctorDept.drRsvOpenYn === 'Y'">
                                            <a href="javascript:;" class="reservation__doctor--burron" @click="getTime (doctor)">선택</a>
                                            <div class="reservation__doctor--img">
                                                <img :src="`/displayFile.do?drNo=${doctor.drNo}`" alt="이미지">
                                            </div>
                                            <div class="reservation__doctor--con">
                                                <div class="reservation__doctor--title">
                                                    <h4>{{ doctor.hptlJobTitle ? doctor.hptlJobTitle : doctor.drName }}</h4>
                                                    <a :href="`/hospital/department-doctor/doctorDetail.do?drNo=${doctor.drNo}`" target="_blank">의료진소개</a>
                                                </div>
                                                <ul class="reservation__doctor--text">
                                                    <li>
                                                        <span>주진료분야</span>
                                                        <p>{{ doctor.doctorDept.special }}</p>
                                                    </li>
                                                    <li>
                                                        <span>빠른진료</span>
                                                        <p>{{ doctor.fastDt }}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </template>
                                </template>
                                <li v-else class="reservation__day--alert">예약 가능한 의료진이 없습니다.</li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li class="reservation__step" :class="step == 3 ? 'active' : ''">
                    <a href="javascript:;" class="reservation__header">
                        <h3 class="reservation__header--title">STEP03</h3>
                        <span class="reservation__header--arrow"></span>
                        <h3 class="reservation__header--title" >{{step == '3' ? '예약일/시간 선택' : '예약일시를 선택해주세요'}}</h3>
                    </a>
                    <div class="reservation__con" :class="step == 3 ? 'active' : ''">
                        <div class="reservation__day-wrap">
                            <sub>
                                * 해당 진료일정은 <span>{{selectedDoctor.hptlJobTitle ? selectedDoctor.hptlJobTitle : selectedDoctor.drName}} 의료진</span>의 소속된 <span>전체(과/클리닉) 진료 일정</span>입니다.
                            </sub>
                            <div class="reservation__day">
                                <div class="reservation__day--header">
                                    <p>현재 가장 빠른 진료예약 일자는 <br class="pc-none"><span>{{ selectedDoctor.fastDt }}</span> 입니다.</p>
                                </div>
                                <div class="reservation__day--con">
                                    <div class="reservation__calendar">
                                        <calendar :classList="classList" @clickFnc="setTime" @getClass="getTime" ref="calendar"></calendar>
                                    </div>
                                    <div class="reservation__time">
                                        <ul v-if="time.am.length > 0">
                                            <li v-for="item in time.am" :key="item">
                                                <a href="javascript:;" :class="item.ordtm.replace(':', '') == selectedTime.ordtm ? 'active' : ''" @click="setInfomation(item)" role="button" style="cursor:pointer;" class="time_mo_hover">
                                                    {{ item.ampm.indexOf('오') >= 0 ? item.ampm : item.ordtm.slice(0,2) +':' + item.ordtm.slice(2,4) }}
                                                </a>
                                            </li>
                                        </ul>
                                        <ul v-if="time.pm.length > 0">
                                            <li v-for="item in time.pm" :key="item">
                                                <a href="javascript:;" :class="item.ordtm.replace(':', '') == selectedTime.ordtm ? 'active' : ''" @click="setInfomation(item)" role="button" style="cursor:pointer;" class="time_mo_hover">
                                                    {{ item.ampm.indexOf('오') >= 0 ? item.ampm : item.ordtm.slice(0,2) +':' + item.ordtm.slice(2,4) }}
                                                </a>
                                            </li>
                                        </ul>
                                        <div v-if="!time.am.length == 0 && !time.pm.length == 0" class="reservation__day--alert">날짜를 선택하시면 예약가능 시간이 표시 됩니다.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="reservation__right">
                <ul class="reservation__step-list">
                    <li :class="step == 1 ? 'active' : ''">
                        <a href="javascript:;">
                            <span>01</span>
                            <h4>{{!selectedDept.deptNm ? '진료과 선택' : selectedDept.deptNm}}</h4>
                        </a>
                    </li>
                    <li :class="step == 2 ? 'active' : ''">
                        <a href="javascript:;">
                            <span>02</span>
                            <h4>{{!selectedDoctor.drName ? '의료진 선택' : selectedDoctor.hptlJobTitle ? selectedDoctor.hptlJobTitle : selectedDoctor.drName}}</h4>
                        </a>
                    </li>
                    <li :class="step == 3 ? 'active' : ''">
                        <a href="javascript:;">
                            <span>03</span>
                            <h4>{{!selectedTime.selDt ? '예약일/시간 선택' : selectedTime.printDt}}</h4>
                        </a>
                    </li>
                </ul>
                <div class="reservation__patient">
                    <h4 class="reservation__patient--title">환자정보</h4>
                    <ul class="reservation__patient--text">
                        <li>
                            <span>성함</span> {{ myInfo.memName }} (환자번호:{{ myInfo.memPatId }})
                        </li>
                        <li>
                            <span>전화</span> {{ myInfo.memHpNo }}
                        </li>
                        <li class="mail">
                            <span>메일</span> {{ myInfo.memEmail }}
                        </li>
                    </ul>
                    <a href="/mypage/member/updateMember.do" class="reservation__patient--button">개인정보 수정</a>
                </div>
                <a href="javascript:;" class="reservation__right--button" @click="reservationChange" v-if="Object.keys($store.state.reserveStore.change).length > 0">예약변경하기</a>
                <a href="javascript:;" class="reservation__right--button" @click="reservation" v-else>예약하기</a>
            </div>
        </div>
    </div>
</template>


<script>
import Consonant from '../common/Consonant.vue'
import Calendar from '../common/Calendar.vue'
import NameCertifyPopUp from './NameCertifyPopUp.vue'
import { useRoute } from 'vue-router'

export default {
    components: {
        Consonant,
        Calendar,
        NameCertifyPopUp
    },
    data() {
        return {
            orgList: {},
            departmentList: {},
            doctorList: [],
            consonantList: [],
            classList: '',
            timeList: '',
            time: {am:[],pm:[]},
            step: 1,
            selectedDept: '',
            selectedDoctor: '',
            selectedTime: {},
            myInfo: '',
            info: this.$store.state.reserveStore.info ? this.$store.state.reserveStore.info : '',

            popupVisible: false,
            fsexamflag: '',
            ampm: '',
        }
    },
    methods: {
        showPopup() {
            this.popupVisible = true;
        },
        hidePopup() {
            this.popupVisible = false;
        },
        selectDept(dept) {
            if(dept.rsvOpenYn == 'Y'){

                const today = new Date();
                const year = today.getFullYear();
                const month = ('0' + (today.getMonth() + 1)).slice(-2);

                const day = ('0' + today.getDate()).slice(-2);

                const whetherDate =  year + month + day;

                this.axios.get(this.api.appointment.reserveWhether,
                    {
                        params: {
                            orddd: whetherDate,
                            deptCd: dept.emrDeptCd,
                        }
                    }).then(res => {
                    this.fsexamflag = res.data.patinfo.patinfolist.otpt_fsexamflag

                    this.axios.get(this.api.doctor.deptDoctorList,
                        {
                            params: {
                                deptCd: dept.deptCd
                                , emrDeptCd: dept.emrDeptCd
                                , exposeYn: 'Y'
                                , fsexamflag: this.fsexamflag
                            }
                        })
                        .then(res => {
                            this.doctorList = res.data.doctorList

                            this.step = 2

                            this.selectedDept = dept

                            if (this.info) {
                                for (let i of this.doctorList) {
                                    if (i.drNo == this.info.drNo && !this.info.drYn) {
                                        this.getTime(i)
                                        break
                                    }
                                }
                                this.info = ''
                            }
                        })
                        .catch(err => {
                            console.error(err)
                        })
                })
            }else{
                alert('온라인으로 예약할 수 없는 진료과입니다.\n270-1000 번으로 문의 부탁드립니다.')
            }
        },
        selectDoctor(doctor) {
            this.axios.post(this.api.doctor.view,
                {
                    drNo: doctor.drNo
                })
                .then(res => {
                    this.step = 3
                    this.selectedDoctor = res.data
                    this.selectedDoctor.drName = doctor.drName
                    this.selectedDoctor.hptlJobTitle = doctor.hptlJobTitle
                })
                .catch(err => {
                    console.error(err)
                })
        },
        selectStep(step) {
            if(step == 1){
                this.selectedDept = ''
            }
            if(step >= 1){
                this.selectedDoctor = {}
            }
            this.step = step
            this.$refs.calendar.today = new Date()
            this.time = {am:[],pm:[]}
            this.selectedTime = {}
        },
        searchConsonant(str){
            if(str == ''){
                this.departmentList = this.orgList
            }else{
                this.departmentList = this.$commonUtils.listToConList(this.orgList, 'deptNm', str)
            }
        },
        getTime(doctor, selDt){
            this.time = {am: [], pm: []}
            for(let i of this.doctorList){
                if(i.drNo == doctor.drNo){
                    if(!i.fastDt){
                        if(this.calcAge(this.myInfo.memBirth) > 18){
                            alert('소아청소년과는 만 18세 미만인 경우에만 예약 가능합니다.')
                        }else{
                            alert('예약 가능한 일정이 없습니다.\n270-1000 번으로 문의 부탁드립니다.')
                        }
                        return
                    }
                }
            }
            if(doctor.doctorDept){
                if(doctor.doctorDept.drRsvOpenYn == 'N'){
                    alert('전화예약만 가능한 의료진입니다.(Tel: 270-1000)')
                    return;
                }
            }

            if(this.step == 2){
                this.axios.get(this.api.doctor.absence, {
                    params: {
                        drNo: doctor.drNo
                    }
                }).then(res => {
                    const reason = res.data.reason

                    if(reason){
                        const startDt = new Date(res.data.staDt)
                        const endDt = new Date(res.data.endDt)
                        const today = new Date()
                        if(today >= startDt && today <= endDt){
                            alert(reason)
                            return
                        }
                    }
                })
            }

            if(this.selectedDoctor.drNo != doctor.drNo){
                this.selectedDoctor = {}
                this.selectedDoctor.drName = doctor.drName
                this.selectedDoctor.hptlJobTitle = doctor.hptlJobTitle
                this.selectedDoctor.drNo = doctor.drNo
                this.selectedDoctor.fastDt = doctor.fastDt
                this.selectedDoctor.empId = doctor.empId
            }

            if (!selDt) {
                const today = new Date();
                const year = today.getFullYear();
                const month = ('0' + (today.getMonth() + 1)).slice(-2);

                selDt = year + month;
            }

            this.axios.get(this.api.appointment.time,
                {
                    params: {
                        drNo: doctor.empId,
                        selDt: selDt,
                        orddeptcd: this.selectedDept.emrDeptCd,
                        fsexamflag: this.fsexamflag
                    }
                })
                .then(res => {
                    this.step = 3
                    if(res.data){
                        if(res.data[0].code){
                            this.classList = []
                        }else{
                            this.timeList = res.data
                            this.$commonUtils.objArrSort(this.timeList, 'orddd', 'ASC')

                            //rsv_pb_cnt가 0이상인 데이터만 사용
                            this.timeList = this.timeList.filter(i => i.rsv_pb_cnt > 0)

                            //현재 날짜 이후 데이터만 사용
                            const today = new Date();
                            const day = ('0' + today.getDate()).slice(-2);

                            const fullDay = today.getFullYear() + ('0' + (today.getMonth() + 1)).slice(-2) + day

                            for(let i of this.timeList){
                                console.log(i.orddd, fullDay)
                            }
                            this.timeList = this.timeList.filter(i => i.orddd > fullDay)

                            let arr = []
                            for(let i of this.timeList){
                                const obj = {}
                                obj.text = i.orddd
                                obj.dayType = 'pos'
                                arr.push(obj)
                            }
                            // 중복 제거
                            let map = new Map();
                            arr = arr.filter(i => {
                                const has = map.has(i.text)
                                return !has && map.set(i.text, 1)
                            })
                            this.classList = arr
                        }
                    }
                    const el = this.$refs.rHeader
                    console.log(el)
                    if(el){
                        const rect = el.getBoundingClientRect()
                        const top = rect.top + window.scrollY
                        window.scrollTo({top: top - 20, behavior: 'smooth'})
                    }
                })
                .catch(err => {
                    console.error(err)
                })


        },
        setTime(item){
            this.selectedTime = {}
            this.time = {am: [], pm: []}
            this.axios.get(this.api.appointment.doctorDayTime,
                {
                    params: {
                        drNo: this.selectedDoctor.empId,
                        selDt: item.text,
                        orddeptcd: this.selectedDept.emrDeptCd,
                        fsexamflag: this.fsexamflag
                    }
                })
                .then(res => {
                    if(res.data) {
                        for (let i of res.data) {
                            i.ampm = ''
                            if (i.ampm_dr_yn === 'Y') {
                                if (i.ampmflag === 'A') {
                                    i.ampm = '오전'
                                    this.time.am.push(i)
                                }
                                if (i.ampmflag === 'P') {
                                    i.ampm = '오후'
                                    this.time.pm.push(i)
                                }
                            }else{
                                if (i.ampmflag === 'P') {
                                    this.time.pm.push(i)
                                }
                                if (i.ampmflag === 'A') {
                                    this.time.am.push(i)
                                }
                            }
                        }

                        // time.am, time.pm 에 ordtm 중복인 데이터 제거
                        if (res.data[0].ampm_dr_yn === 'Y') {
                            this.time.am = this.time.am.filter((item, index, self) => self.findIndex(t => t.ampm === item.ampm) === index)
                            this.time.pm = this.time.pm.filter((item, index, self) => self.findIndex(t => t.ampm === item.ampm) === index)
                        }
                    }
                })
                .catch(err => {
                    console.error(err)
                })

        },
        setInfomation(item){
            this.selectedTime = {}
            const dt = `${item.orddd.slice(0, 4)}-${item.orddd.slice(4, 6)}-${item.orddd.slice(6, 8)}(${ this.$commonUtils.getDayOfWeek(item.orddd)})`
            this.selectedTime.selDt = dt + ' ' + item.ordtm.slice(0, 2) + ':' + item.ordtm.slice(2, 4)
            this.selectedTime.printDt = dt + ' ' + ( item.ampm.indexOf('오') >= 0 ? item.ampm : item.ordtm.slice(0, 2) + ':' + item.ordtm.slice(2, 4) )
            this.selectedTime.dt = dt
            this.selectedTime.time = item.ordtm
            this.selectedTime.orddd = item.orddd
            const hour = new Date(this.selectedTime.selDt).getHours()
            const min = new Date(this.selectedTime.selDt).getMinutes()
            const hm = ('0' + (hour)).slice(-2) + ('0' + (min)).slice(-2)
            if(item.ordtm){
                this.selectedTime.ordtm = item.ordtm
            }else{
                this.selectedTime.ordtm = hm
            }

            this.ampm = item.ampm
        },
        reservation(){
            if(!this.selectedTime.orddd || !this.selectedTime.ordtm || !this.selectedDoctor.drName){
                alert('예약정보 입력 후 예약 가능합니다.')
                return
            }
            if(this.selectedTime.ordtm.indexOf('NaN') > -1 || isNaN(this.selectedTime.ordtm)){
                alert('날짜값에 오류가 발생했습니다. 다시 시도해주시길 바랍니다.')
                location.reload(true)
                return
            }

            this.axios
                .get(this.api.mypage.reservationList, {
                    params: {
                        fromdd : this.$commonUtils.getToday(),
                        todd : this.$commonUtils.addDate("y", 1, this.$commonUtils.getToday()),
                    },
                })
                .then((res) => {
                    const list = res.data.list ? res.data.list : []
                    if(list.length > 0){
                        let checkDup = false
                        
                        for(let i of list){
                            if(i.orddeptcd == this.selectedDept.emrDeptCd && i.orddrid == this.selectedDoctor.empId){
                                alert('동일한 의료진으로 중복 예약하실 수 없습니다.')
                                checkDup = true
                                break
                            }
                        }

                        if(checkDup) return
                    }

                    const param = {
                        sessuserid : '90000300',
                        flag : 'I', // 접수: I, 취소: D
                        // pid : this.myInfo.memPatId,
                        orddd : this.selectedTime.orddd,
                        ordtm : this.selectedTime.ordtm,
                        orddeptcd : this.selectedDept.emrDeptCd,
                        orddrid : this.selectedDoctor.empId,
                        // cretno : '',
                        // insukind : '',
                        // suppkind : '',
                        // rsrvflag : '6',
                        // etcordflag : 'R6'
                    }

                    this.axios({
                        method: 'post',
                        url: this.api.appointment.reservation,
                        params: param
                    }).then(res => {
                        if(res.data.errormsg){
                            alert(res.data.errormsg)
                            if(res.data.errortype == 'currentMember_null') location.reload(true)
                            return
                        }

                        const data = res.data
                        data.otpt_ordtm = this.selectedTime.ordtm
                        data.deptNm = this.selectedDept.deptNm
                        data.dt = this.selectedTime.dt
                        data.time = this.selectedTime.time
                        data.drName = this.selectedDoctor.drName
                        data.hptlJobTitle = this.selectedDoctor.hptlJobTitle
                        data.clsf = 'N'

                        data.ampm = this.ampm

                        this.$store.commit('setReserve', data)
                        this.$router.push('/reserve/reservationComplete.do')
                    }).catch(err => {
                        alert('오류가 발생했습니다.')
                        location.reload(true);
                    })
                })
                .catch((err) => {
                    alert('예약 조회 중 오류가 발생했습니다.');
                });
        },
        reservationChange(){
            if(!this.selectedTime.orddd || !this.selectedTime.ordtm || !this.selectedDoctor.drName){
                alert('예약정보 입력 후 예약 변경 가능합니다.')
                return
            }
            if(this.selectedTime.ordtm.indexOf('NaN') > -1 || isNaN(this.selectedTime.ordtm)){
                alert('날짜값에 오류가 발생했습니다. 다시 시도해주시길 바랍니다.')
                location.reload(true)
                return
            }

            const info = this.$store.state.reserveStore.change

            const param = {
                // instcd : '',
                // pid : '',
                orddd_old : info.orddd,
                cretno_old : info.cretno,
                orddd_new : this.selectedTime.orddd,
                ordtm_new : this.selectedTime.ordtm,
                userid : '90000300',
                orddrid_new : this.selectedDoctor.empId,
                orddeptcd_new : this.selectedDept.emrDeptCd
            }

            this.axios({
                method: 'post',
                url: this.api.appointment.reservationChange,
                params: param
            }).then(res => {
                if(res.data.errormsg){
                    alert(res.data.errormsg)
                    if(res.data.errortype == 'currentMember_null') location.reload(true)
                    return
                }

                const data = res.data
                data.otpt_orddd = this.selectedTime.orddd
                data.otpt_ordtm = this.selectedTime.ordtm
                data.otpt_orddeptcd = this.selectedDept.emrDeptCd
                data.otpt_orddrid = this.selectedDoctor.empId
                data.otpt_cretno = info.cretno
                data.deptNm = this.selectedDept.deptNm
                data.dt = this.selectedTime.dt
                data.time = this.selectedTime.time
                data.drName = this.selectedDoctor.drName
                data.hptlJobTitle = this.selectedDoctor.hptlJobTitle
                data.clsf = 'C'

                data.ampm = this.ampm

                this.$store.commit('setReserve', data)
                this.$router.push('/reserve/reservationComplete.do')
            }).catch(err => {
                alert('오류가 발생했습니다.')
                location.reload(true);
            })
        },
        updateMember(){
            this.axios({
                method:"post",
                url: this.api.member.updateMember,
                params: memberParam
            }).then(response => {
                console.log(response.data);
            });
        },
        calcAge(date){
            const year = date.slice(0,4)
            const month = date.slice(4,6)
            const day = date.slice(6)
            const today = new Date();
            const birthDate = new Date(year, month, day);

            let age = today.getFullYear() - birthDate.getFullYear();
            const m = (today.getMonth() + 1) - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            return age;
        }
    },
    mounted() {
        this.myInfo = this.$store.state.myInfoStore
        console.log(this.myInfo);
        this.popupVisible = this.myInfo.memPatId == '' || this.myInfo.memPatId == null ? true : false

        this.axios.get(this.api.department.list,
            {
                params: {
                    deptClsf: 'A'
                }
            })
            .then(res => {
                // 예약여부 필수여부 확인필요
                // this.departmentList = res.data.deptList.filter(el => el.rsvOpenYn == 'Y')
                this.departmentList = res.data.deptList
                const fifteen = ['MK', 'ME', 'MA', 'MF', 'MD', 'NP', 'MJ']
                const eighteen = ['MC', 'MB', 'NU', 'MK']
                if(this.myInfo.memBirth){
                    if(this.calcAge(this.myInfo.memBirth) < 15){
                        this.departmentList = this.departmentList.filter(el => !fifteen.includes(el.deptCd))
                    }
                    if(this.calcAge(this.myInfo.memBirth) < 18){
                        this.departmentList = this.departmentList.filter(el => !eighteen.includes(el.deptCd))
                    }
                }

                this.orgList = this.departmentList
                this.consonantList = this.$commonUtils.setConsonantList(this.orgList, 'deptNm')
            })
            .catch(err => {
                console.error(err)
            })

        if(this.info){
            const data = this.info.doctorDept ? this.info.doctorDept : this.info
            this.selectDept(data)
        }

        // window.addEventListener('beforeunload', () => {
        //     this.$store.commit('setReserveInfo', '')
        // });
    },
    beforeUnmount() {
        if(useRoute().path != '/reserve/reservationDoctor.do') this.$store.commit('setChange', '')
        this.$store.commit('setReserveInfo', '')
    },
}
</script>